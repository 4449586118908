import React, { useRef, useContext, useEffect, useState } from 'react'
import styles from './Hero.module.scss'
import classNames from 'classnames'
import ScrollSectionContext from '../../context/ScrollContext'
import bgVideo from '../../assets/images/kk-vefur-BG-1080.mp4'

const Hero = ({ title, subheadline, img, alt }) => {
  const leadClass = classNames(styles.heroLead, 'pl-md-4 d-block d-md-inline')
  const scrollSection = useContext(ScrollSectionContext)
  const heroRef = useRef(null)
  const headlineRef = useRef()
  const titleArr = title ? title.split(' ') : []
  const [typeStyle, setTypeStyle] = useState()
  const [typeStyleFurther, setTypeStyleFurther] = useState()
  const [videoPerspective, setVideoPerspective] = useState()

  useEffect(() => {
    if (Array.isArray(scrollSection)) {
      scrollSection.push(heroRef)
    }

    return () => {
      const index = scrollSection.indexOf(heroRef)
      if (index > -1) {
        scrollSection.splice(index, 1)
      }
    }
  }, [])

  const logMousePosition = e => {
    const XRel = e.pageX - headlineRef.current.offsetLeft
    const YRel = e.pageY - headlineRef.current.offsetTop
    const width = headlineRef.current.offsetWidth

    const YAngle = -(0.5 - (XRel / width)) * 2
    const XAngle = (0.5 - (YRel / width)) * 2

    setTypeStyle({
      transform: 'perspective(525px) translateZ(' + 0 + 'px) rotateX(' + XAngle + 'deg) rotateY(' + YAngle + 'deg)',
      transition: 'none'
    })

    setTypeStyleFurther({
      transform: 'perspective(525px) translateZ(' + 0 + 'px) rotateX(' + (XAngle * 0.5) + 'deg) rotateY(' + (YAngle * 0.5) + 'deg)',
      transition: 'none'
    })

    setVideoPerspective({
      transform: 'perspective(1025px) translateX(-50%) translateY(-50%) translateZ(' + 50 + 'px) rotateX(' + (XAngle * 0.1) + 'deg) rotateY(' + (YAngle * 0.1) + 'deg)',
      transition: 'none'
    })
  }

  useEffect(() => {
    window.addEventListener('mousemove', logMousePosition)
    return () => {
      window.removeEventListener('mousemove', logMousePosition)
    }
  }, [])

  return (
    <section className={classNames(styles.container, 'mb-4 mb-md-100 position-relative overflow-hidden')} ref={heroRef}>
      { /* eslint-disable */ }
      <video width='1920' height='1080' muted playsInline autoPlay loop className={styles.video} style={videoPerspective}>
        <source src={bgVideo} type='video/mp4' />
      </video>
      { /* eslint-enable */ }
      <div className='container pb-5'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='hdln--hero hdln--white d-block d-md-flex flex-wrap' ref={headlineRef} style={typeStyle}>
              {titleArr.map((item, i) => {
                return (
                  <span
                    className={classNames('d-inline-block',
                      { 'mr-md-4 mr-3': i !== titleArr.length - 1 }
                    )}
                    key={i}
                  >
                    {item}
                  </span>
                )
              })}
              <span className={leadClass} style={typeStyleFurther}>{subheadline}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
