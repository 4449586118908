import React from 'react'
import classNames from 'classnames'
import { Link } from '../'

const StaffItem = ({ name, title, image, alt, className, onClickHandler, link }) => {
  const containerClass = classNames(className, 'button--nostyle p-md-0 p-1')
  return (
    <>
      {!link ? (
        <button className={containerClass} onClick={onClickHandler}>
          <img className='w-100' src={image} alt={alt} />
          <h2 className='hdln--author pt-3'>{name}</h2>
          <div className='hdln--subheadline mt-1 mt-md-0'>{title}</div>
        </button>
      ) : (
        <Link className={containerClass} to={link}>
          <img className='w-100' src={image} alt={alt} />
          <div className='pl-md-0 pl-1'>
            <h2 className='hdln--author pt-3'>{name}</h2>
            <div className='hdln--subheadline mt-1 mt-md-0'>{title}</div>
          </div>
        </Link>
      )}
    </>
  )
}

export default StaffItem
