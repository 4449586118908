import React, { useRef, useContext, useEffect } from 'react'
import styles from './Footer.module.scss'
import { Link, FooterBottom, FadeBottom } from '../'
import classNames from 'classnames'
import ScrollSectionContext from '../../context/ScrollContext'

const Footer = ({ headline, text, linkText, link, img, imgLinkText, imgLink, isRed }) => {
  const scrollSection = useContext(ScrollSectionContext)
  const footerTopRef = useRef(null)

  useEffect(() => {
    if (Array.isArray(scrollSection) && !isRed) {
      scrollSection.push(footerTopRef)
    }

    return () => {
      if (!Array.isArray(scrollSection)) {
        return
      }

      const index = scrollSection.indexOf(footerTopRef)
      if (index > -1) {
        scrollSection.splice(index, 1)
      }
    }
  }, [])

  const sectionClass = classNames(
    'py-5 pb-6 py-md-6',
    styles.footer,
    { [styles.redTheme]: isRed }
  )

  const headlineClass = classNames(
    'hdln--2 mb-0 mb-md-2 pt-md-4',
    { 'hdln--white': !isRed },
    { 'hdln--dark-blue': isRed }
  )

  const pClass = classNames(
    'parag--large',
    { 'hdln--white': !isRed }
  )

  const buttonClass = classNames(
    'button mt-3 mt-md-4',
    { 'button--white': !isRed }
  )

  const socialLinkClass = classNames(
    'link--social link--instagram d-block mt-30',
    { 'link--social-white': !isRed }
  )

  return (
    <footer>
      <div className={sectionClass} ref={footerTopRef}>
        <div className='container my-md-100'>
          <div className='row d-flex flex-column-reverse flex-md-row'>
            <FadeBottom delay={200}>
              <div className='col-md-7 pl-md-5'>
                <h1 className={headlineClass}>{headline}</h1>
                <div className={pClass} dangerouslySetInnerHTML={{ __html: text }} />
                <Link to={link} className={buttonClass}>{linkText}</Link>
              </div>
            </FadeBottom>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  )
}

export default Footer
