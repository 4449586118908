import React from 'react'
import { Link, FadeBottom } from '../'

const SectionDropLine = ({ title, link, linkText }) => {
  return (
    <section className='bg--blue-extend mb-md-100 mr-3 mr-md-0'>
      <div className='container'>
        <div className='row py-100'>
          <div className='col-lg-5'>
            <FadeBottom>
              <h1 className='hdln--3 hdln--white m-0'>{title}</h1>
            </FadeBottom>
          </div>

          <div className='col-6 col-lg-3 offset-md-1 pt-4 d-flex flex-column justify-content-end '>
            <FadeBottom delay={200}>
              <Link to={link} className='button button--white mb-3'>{linkText}</Link>
            </FadeBottom>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionDropLine
