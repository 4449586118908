import React, { useState } from 'react'
import styles from './SectionGallery.module.scss'
import Masonry from 'react-masonry-css'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import classNames from 'classnames'
import { FadeBottom } from '../'

const SectionGallery = ({ title, subtitle, images, fullWidth }) => {
  const [currentItemNr, setcurrentItemNr] = useState(0)
  const [isOpen, setisOpen] = useState(false)
  const breakpointColumnsObj = {
    default: fullWidth ? 3 : 2,
    1100: 2,
    700: 2
  }

  const openImage = value => {
    setcurrentItemNr(value)
    setisOpen(true)
  }

  const closeImage = value => {
    setisOpen(false)
  }

  const previous = event => {
    setcurrentItemNr((currentItemNr + images.length - 1) % images.length)
  }

  const next = event => {
    setcurrentItemNr((currentItemNr + 1) % images.length)
  }

  const containerClass = classNames(
    'py-4 py-md-100',
    { 'container': !fullWidth }
  )

  return (
    <section className={containerClass}>
      <div className='row pb-4'>
        <div className='col-md-8'>
          {subtitle &&
            <FadeBottom delay={200}>
              <span className='hdln--secondary'>{subtitle}</span>
            </FadeBottom>
          }
          {title &&
            <FadeBottom>
              <div className='hdln-skraut--4'>
                <h1 className='hdln--2 hdln--gradient pr-3 mb-0 pt-md-2'>{title}</h1>
              </div>
            </FadeBottom>
          }
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={styles.galleryGrid}
            columnClassName={styles.galleryGridColumn}>
            { images.map((item, i) => {
              return (
                <FadeBottom key={i}>
                  <button className='button--nostyle w-100 mb-2 mb-md-60' onClick={() => openImage(i)}>
                    <img src={item.src} className='w-100' alt={item.alt} />
                  </button>
                </FadeBottom>
              )
            })}
          </Masonry>

          {isOpen && (
            <Lightbox
              mainSrc={images[currentItemNr].src}
              nextSrc={images[(currentItemNr + 1) % images.length].src}
              prevSrc={images[(currentItemNr + images.length - 1) % images.length].src}
              onCloseRequest={closeImage}
              onMovePrevRequest={previous}
              onMoveNextRequest={next}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default SectionGallery
