import React from 'react'
import Fade from 'react-reveal/Fade'

const FadeBottom = ({ delay, children }) => {
  return (
    <Fade delay={delay} bottom duration={500} distance={'40px'}>
      {children}
    </Fade>
  )
}

export default FadeBottom
