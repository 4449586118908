import React from 'react'
import classNames from 'classnames'
import { FadeBottom } from '../'

const SectionStandard = ({ text }) => {
  return (
    <FadeBottom>
      <section className={classNames('section-standard', 'container')}>
        <div className='row'>
          <div className='col-md-10 offset-md-1'>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>
        </div>
      </section>
    </FadeBottom>
  )
}

export default SectionStandard
