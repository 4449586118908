import React from 'react'
import { Link } from '../'
import Modal from 'react-modal'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faTimes, faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons'
import styles from './StaffModal.module.scss'

const StaffModal = ({ item, showModal, closeModalHandler, nextHandler, previousHandler }) => {
  const socialClass = classNames(
    'col-md-1 order-2 order-md-1 d-flex flex-md-column align-items-end pt-3 pt-md-0 px-2 pr-3',
    styles.socialContainer
  )
  return (
    <Modal
      isOpen={showModal}
      className={classNames(styles.modal, 'Modal')}
      contentLabel='Staff member'
    >
      <div className={styles.topBar}>
        <button
          onClick={closeModalHandler}
          className={classNames('button--nostyle', styles.closeButton)}>
          <FontAwesomeIcon icon={faTimes} aria-hidden="true" alt="close window" />
        </button>

        <button
          onClick={previousHandler}
          className={classNames('button--nostyle', styles.nextButton)}>
          <FontAwesomeIcon icon={faChevronLeft} aria-hidden="true" alt="previous item" />
        </button>

        <button
          onClick={nextHandler}
          className={classNames('button--nostyle', styles.previousButton)}>
          <FontAwesomeIcon icon={faChevronRight} aria-hidden="true" alt="next item" />
        </button>
      </div>

      <div className={classNames('container py-4 py-md-100', styles.modalContainer)}>
        <div className={classNames('row', styles.modalRow)}>
          <div className='col-md-6 order-3 order-md-1 px-2'>
            <h2 className='hdln--3 hdln--dark-blue'>{item.name}</h2>
            <h3 className='hdln--5 hdln--dark-blue pb-md-4'>{item.title}</h3>
            <div className='parag--small' dangerouslySetInnerHTML={{ __html: item.info }} />
          </div>
          <div className={socialClass}>
            {item.email &&
              <Link to={'mailto:' + item.email} className='pb-3 mr-3 mr-md-0'><FontAwesomeIcon icon={faEnvelope} aria-hidden="true" alt="email merki" /></Link>
            }

            {item.linkedin &&
              <Link to={item.linkedin} className='pb-3 mr-3 mr-md-0'><FontAwesomeIcon icon={faLinkedinIn} aria-hidden="true" alt="Linkedin merki" /></Link>
            }

            {item.instagram &&
              <Link to={item.instagram} className='pb-3 mr-3 mr-md-0'><FontAwesomeIcon icon={faInstagram} aria-hidden="true" alt="instagram merki" /></Link>
            }
          </div>
          <div className='col-md-5 order-md-3 px-2'>
            <img src={item.image} alt={item.alt} className='w-100' />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StaffModal
