import React from 'react'
import classNames from 'classnames'
import { Link, FadeBottom } from '../'

const FootnoteList = ({ footnotes }) => {
  return (
    <section className='bg--grey pb-100'>
      <div className='container'>
        {footnotes.map((item, i) => {
          const spacingClass = classNames('row', { 'pb-5': i === footnotes.length - 1 })
          return (
            <FadeBottom>
              <div className={spacingClass} key={i}>
                <div className='col-md-12'>
                  <Link to={item.link} className='link--standard hdln--subheadline'>{item.text}</Link>
                </div>
              </div>
            </FadeBottom>
          )
        })}
      </div>
    </section>
  )
}

export default FootnoteList
