import React from 'react'
import { Link, TwoColImgText, TwoColQuote, FadeBottom } from '../'

const SectionServicesList = ({ title, lead, items, link, linkText }) => {
  return (
    <section className='container py-4 py-md-100'>
      {title &&
        <div className='row pb-4'>
          <div className='col-md-8'>
            <FadeBottom delay={200}>
              <span className='hdln--secondary'>{lead}</span>
            </FadeBottom>
            <FadeBottom>
              <div className='hdln-skraut--3'>
                <h1 className='hdln--2 hdln--gradient pr-3 mb-0 pt-md-2'>{title}</h1>
              </div>
            </FadeBottom>
          </div>

          <div className='col-lg-4 col-md-4 text-md-right pt-md-4 d-none d-md-block'>
            {link &&
              <FadeBottom delay={200}>
                <Link to={link} className='link--seemore'>{linkText}</Link>
              </FadeBottom>
            }
          </div>
        </div>
      }

      {items.map((item, i) => {
        if (item.type === 'quote') {
          return (
            <FadeBottom key={i}>
              <TwoColQuote
                title={item.title}
                text={item.text}
                image={item.image}
                alt={item.alt}
                name={item.name}
                job={item.job}
              />
            </FadeBottom>
          )
        } else {
          return (
            <FadeBottom key={i}>
              <TwoColImgText
                title={item.title}
                text={item.text}
                image={item.image}
                alt={item.alt}
                link={item.link}
                linkText={item.linkText}
                reverse={i % 2}
                extend={item.extend}
                imgTag={item.imgTag}
              />
            </FadeBottom>
          )
        }
      })}
    </section>
  )
}

export default SectionServicesList
