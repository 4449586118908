import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
query {
  prismicFooter {
    id
    data {
      headline {
        text
      }
      text {
        html
      }
      link_text {
        text
      }
      link {
        url
      }
      img {
        url
        alt
      }
      img_link_text {
        text
      }
      img_link {
        url
      }
    }
  }
}
`

const withFooterContent = WrappedComponent => {
  const Connection = props => {
    return (
      <StaticQuery
        query={query}
        render={data => {
          const { prismicFooter: { data: content } } = data

          return (
            <WrappedComponent
              headline={content.headline.text}
              text={content.text.html}
              linkText={data.prismicFooter.data.link_text.text}
              link={data.prismicFooter.data.link.url}
              img={data.prismicFooter.data.img.url}
              imgLinkText={data.prismicFooter.data.img_link_text.text}
              imgLink={data.prismicFooter.data.img_link.url}
              {...props}
            />
          )
        }}
      />
    )
  }
  return Connection
}

export default withFooterContent
