import React from 'react'
import styles from './Slices.module.scss'
import { reporter } from 'gatsby'

export default sliceMapping => ({ body, ...rest }) => (
  body.map(({ __typename: type, ...data }, i) => {
    const sliceMapper = sliceMapping[type]

    return (
      <Slice
        key={i}
        sliceMapper={sliceMapper}
        passThru={rest}
        data={data}
        type={type}
      />
    )
  })
)

const Slice = ({ sliceMapper, passThru, type, data }) => {
  if (!sliceMapper || !sliceMapper.component || !sliceMapper.dataToProps) {
    console.error(`Unknown slice type or component for ${type}`)

    return (
      <DisplayMessage type={type}>
        Something went wrong finding the corresponding UI component for
        this slice type
      </DisplayMessage>
    )
  }

  const { component: Cmp, dataToProps } = sliceMapper

  let dataProps

  try {
    dataProps = dataToProps(data, passThru)
  } catch (e) {
    if (typeof window === 'undefined') {
      throw e
    } else {
      console.error(e)
    }

    return (
      <DisplayMessage type={type}>
        <p>
          Something went wrong trying to render this UI component (slice).
        </p>

        <p>
          These types of errors are usually caused by fields in the relevant
          entry being missing.
        </p>
      </DisplayMessage>
    )
  }

  const props = {
    ...passThru,
    ...dataProps
  }

  return (
    <ErrorBoundary type={type} data={dataProps}>
      <Cmp {...props} />
    </ErrorBoundary>
  )
}

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)

    this.state = { error: null }
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  render () {
    const { children, type, data } = this.props

    if (this.state.error) {
      return <DisplayMessage data={data} type={type} />
    }

    return children
  }
}

const DisplayMessage = ({ type, data, children }) => {
  if (typeof window === 'undefined') {
    return null
  }

  return (
    <div className={styles.errorMessage}>
      <h4 data-type={type}>Úps!</h4>

      {typeof children === 'string' ? <p>{children}</p> : children}

      {data && (
        <div style={{ display: 'none' }}>
          <pre>
            {JSON.stringify(data)}
          </pre>
        </div>
      )}
    </div>
  )
}
