import React from 'react'
import { FadeBottom } from '../'

const SectionServiceSteps = ({ title, text, steps, footnotes }) => {
  return (
    <section className='bg--sidedec pt-md-100 pb-4'>
      <div className='container pt-md-100'>
        <div className='row pb-4 pb-md-5 pt-100 pt-md-0'>
          <div className='col-md-10 d-flex align-items-baseline flex-wrap'>
            <FadeBottom>
              <h1 className='hdln--1 pr-3 mb-0'>{title}</h1>
            </FadeBottom>
            <FadeBottom delay={200}>
              <div className='lead lead--intro' dangerouslySetInnerHTML={{ __html: text }} />
            </FadeBottom>
          </div>
        </div>

        {steps.map((item, i) => {
          return (
            <FadeBottom>
              <div className='row pt-5 pt-md-6 pb-4' key={i}>
                <div className='col-md-12'>
                  <h2 className='hdln--2 pr-3 mb-0'>{item.nr}</h2>
                  <div className='lead lead--small' dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
              </div>
            </FadeBottom>
          )
        })}
      </div>
    </section>
  )
}

export default SectionServiceSteps
