import React, { useState, useRef } from 'react'
import { FadeBottom } from '../'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip, faTimes } from '@fortawesome/pro-light-svg-icons'

const SectionApplication = ({ title, text }) => {
  const [inputFile, setinputFile] = useState('')
  const inputFileElem = useRef()

  const onInputChange = inputFile => {
    if (inputFile && inputFile.target.files[0]) {
      setinputFile(inputFile.target.files[0].name)
    }
  }

  const removeFile = event => {
    event.preventDefault()
    inputFileElem.current.value = ''
    setinputFile(null)
  }

  return (
    <section className='container pt-4 pb-5 py-md-100'>
      <div className='row'>
        <div className='col-md-5'>
          <FadeBottom>
            <h1 className='hdln--2'>{title}</h1>
            <div className='pt-3 pt-md-4 mt-md-1' dangerouslySetInnerHTML={{ __html: text }}></div>
          </FadeBottom>
        </div>
        <div className='col-md-5 offset-md-1 pt-5 pt-md-0'>
          <FadeBottom delay={200}>
            <form name='application' method='POST' data-netlify='true' action='/thank-you'>
              <input type='hidden' name='form-name' value='application' />
              <div className='form-group'>
                <label htmlFor='f-name'>Your full name</label>
                <input className='form-control w-100' name='name' type='text' id='f-name' />
              </div>
              <div className='form-group pt-4'>
                <label htmlFor='f-email'>Your email</label>
                <input className='form-control w-100' name='email' type='email' id='f-email' />
              </div>
              <div className='form-group pt-4'>
                <label htmlFor='f-link'>Link to your portfolio / dribble / behance</label>
                <input className='form-control w-100' name='portfolio' type='text' id='f-link' />
              </div>
              <div className='form-group pt-4'>
                <label htmlFor='f-words'>Want to say a few words?</label>
                <textarea className='form-control w-100' name='words' type='text' id='f-words' ></textarea>
              </div>

              <div className='form-group pt-4'>
                <label htmlFor='f-file'>
                  <FontAwesomeIcon icon={faPaperclip} aria-hidden="true" alt="select file" /> Add attachment
                  {inputFile &&
                    <>
                      <span className='pl-4 font-italic'>{inputFile}</span>
                      {/* eslint-disable */}
                      <a href='#' onClick={removeFile} className='pl-2 button--nostyle'>
                        <FontAwesomeIcon icon={faTimes} className='pt-1' aria-hidden="true" alt="remove file" />
                      </a>
                      {/* eslint-enable */}
                    </>
                  }
                </label>
                <input className='form-control' name='attachment' type='file' id='f-file' ref={inputFileElem} onChange={onInputChange} />
              </div>

              <div className='form-group pt-4 text-md-right'>
                <button className='button' type='submit'>Submit</button>
              </div>
            </form>
          </FadeBottom>
        </div>
      </div>
    </section>
  )
}

export default SectionApplication
