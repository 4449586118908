import React from 'react'
import { FadeBottom } from '../'

const SectionSendMessage = ({ title, text }) => {
  return (
    <section className='container pt-4 pb-5 py-md-100'>
      <div className='row'>
        <FadeBottom delay={200}>
          <div className='col-md-5'>
            <h1 className='hdln--2'>{title}</h1>
            <div className='pt-3 pt-md-4 mt-md-1' dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </FadeBottom>
        <FadeBottom delay={400}>
          <div className='col-md-5 offset-md-1 pt-5 pt-md-0'>
            <form name='contact' method='POST' data-netlify='true' action='/thank-you'>
              <input type='hidden' name='form-name' value='contact' />
              <div className='form-group'>
                <label htmlFor='f-name'>Your full name</label>
                <input type='text' name='name' className='form-control w-100' id='f-name' />
              </div>
              <div className='form-group pt-4'>
                <label htmlFor='f-email'>Your email</label>
                <input type='email' name='email' id='f-email' className='form-control w-100' />
              </div>
              <div className='form-group pt-4'>
                <label htmlFor='f-msg'>How can we help you?</label>
                <textarea name='message' id='f-msg' className='form-control w-100'></textarea>
              </div>
              <div className='form-group pt-4 text-md-right'>
                <button className='button' type='submit'>Submit</button>
              </div>
            </form>
          </div>
        </FadeBottom>
      </div>
    </section>
  )
}

export default SectionSendMessage
