import React from 'react'
import { Link, FadeBottom } from '../'

const SectionProjectInfo = ({ title, text, logo, expertise, link, linkText, year, color }) => {
  const colorStyle = {
    color: color.color
  }

  return (
    <section className='container py-4 py-md-100'>
      <div className='row'>
        <div className='col-md-5'>
          <FadeBottom>
            <h1 className='hdln--3 mb-0 mb-md-3' style={colorStyle}>{title}</h1>
          </FadeBottom>
        </div>

        <div className='col-md-6 offset-md-1 pt-md-4'>
          <FadeBottom delay={200}>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </FadeBottom>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-md-4 offset-md-7 py-4 py-md-6'>
          <FadeBottom>
            <img src={logo} alt='logo' className='w-100' />
          </FadeBottom>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-3 offset-md-6 pb-4 pb-md-0'>
          <FadeBottom>
            <h2 className='hdln--6 mb-0 mb-md-2' style={colorStyle}>Project roles</h2>
            <div className='parag--small' dangerouslySetInnerHTML={{ __html: expertise }} />
          </FadeBottom>
        </div>

        <div className='col-md-3 d-md-flex flex-column justify-content-between'>
          <FadeBottom>
            <div>
              <h2 className='hdln--6 mb-0 mb-md-2' style={colorStyle}>Year</h2>
              <p className='parag--small'>{year}</p>
            </div>
            <Link to={link} className='button mt-3 mt-md-auto'>{linkText}</Link>
          </FadeBottom>
        </div>
      </div>
    </section>
  )
}

export default SectionProjectInfo
