import React, { useState } from 'react'
import Masonry from 'react-masonry-css'
import styles from './SectionStaff.module.scss'
import { StaffItem, StaffModal, FadeBottom } from '../'

const SectionStaff = ({ title, subtitle, items }) => {
  const [showModal, setshowModal] = useState(false)
  const [currentItemNr, setcurrentItemNr] = useState(0)
  const [currentStaffItem, setcurrentStaffItem] = useState(items[0] ? items[0] : null)
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2
  }

  const revealModal = (item, nr) => {
    setshowModal(true)
    setcurrentItemNr(nr)
    setcurrentStaffItem(item)
  }

  const closeModal = event => {
    setshowModal(false)
  }

  const nextItem = () => {
    const nextItemNr = currentItemNr + 1 < items.length ? currentItemNr + 1 : 0

    if (items[nextItemNr].link !== null) {
      skipItem(true)
      return
    }

    setcurrentItemNr(nextItemNr)
    setcurrentStaffItem(items[nextItemNr])
  }

  const skipItem = forward => {
    const nextItemNr = currentItemNr + 2 < items.length ? currentItemNr + 2 : 0
    const prevItemNr = currentItemNr - 2 > -1 ? currentItemNr - 2 : items.length - 2
    const skipToNr = forward ? nextItemNr : prevItemNr

    setcurrentItemNr(skipToNr)
    setcurrentStaffItem(items[skipToNr])
  }

  const previousItem = () => {
    const prevItemNr = currentItemNr - 1 > -1 ? currentItemNr - 1 : items.length - 1

    if (items[prevItemNr].link !== null) {
      skipItem()
      return
    }

    setcurrentItemNr(prevItemNr)
    setcurrentStaffItem(items[prevItemNr])
  }

  return (
    <section>
      <div className='container pt-4 pt-md-100 pb-3 pb-md-4'>
        <div className='row pb-2'>
          <div className='col-12'>
            <FadeBottom delay={200}>
              <span className='hdln--secondary'>{subtitle}</span>
            </FadeBottom>
            <FadeBottom>
              <div className='hdln-skraut--5'>
                <h1 className='hdln--2 hdln--gradient pr-3 mb-0'>{title}</h1>
              </div>
            </FadeBottom>
          </div>
        </div>
      </div>

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={styles.staffGrid}
        columnClassName={styles.staffGridColumn}>
        { items.map((item, i) => {
          const delay = i % 2 ? 0 : 200
          return (
            <FadeBottom delay={delay} key={i}>
              <StaffItem
                name={item.name}
                title={item.title}
                image={item.image}
                alt={item.name}
                className={'pb-4'}
                link={item.link}
                onClickHandler={() => revealModal(item, i)}
              />
            </FadeBottom>
          )
        })}
      </Masonry>
      <StaffModal
        item={currentStaffItem}
        showModal={showModal}
        closeModalHandler={closeModal}
        nextHandler={nextItem}
        previousHandler={previousItem}
      />
    </section>
  )
}

export default SectionStaff
