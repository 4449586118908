import React from 'react'
import { FadeBottom } from '../'

const SectionTitleLeadImg = ({ title, lead, text, image, alt }) => {
  return (
    <section className='container py-4 py-md-100'>
      <div className='row pb-2'>
        <div className='col-12'>
          <FadeBottom>
            <h1 className='hdln--2'>{title}</h1>
          </FadeBottom>
          <FadeBottom delay={200}>
            <div className='lead' dangerouslySetInnerHTML={{ __html: lead }} />
          </FadeBottom>
        </div>
      </div>

      {(image || text) &&
        <div className='row pt-md-4'>
          <div className='col-md-5'>
            <FadeBottom>
              <img className='w-100' src={image} alt={alt} />
            </FadeBottom>
          </div>
          <div className='col-md-6 offset-md-1 pt-4 pt-md-0'>
            <FadeBottom delay={200}>
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </FadeBottom>
          </div>
        </div>
      }
    </section>
  )
}

export default SectionTitleLeadImg
