import React, { useRef, useContext, useEffect, useState } from 'react'
import styles from './HeroSubpage.module.scss'
import classNames from 'classnames'
import ScrollSectionContext from '../../context/ScrollContext'
import Fade from 'react-reveal/Fade'
import { FadeBottom, FooterBottom } from '../'

const HeroSubpage = ({
  title,
  subheadline,
  bgImage,
  image,
  isProject,
  isRed,
  children,
  isContact,
  color,
  isDesignSprint
}) => {
  const scrollSection = useContext(ScrollSectionContext)
  const heroRef = useRef(null)
  const [cloudTransformLeft, setCloudTransformLeft] = useState()
  const [cloudTransformRight, setCloudTransformRight] = useState()

  const sectionClass = classNames(
    styles.container,
    { [styles.contact]: children || isContact },
    { [styles.defaultBg]: !isProject && !isRed },
    { [styles.red]: isRed },
    { [styles.designBg]: isDesignSprint },
    'mb-4 mb-md-100 position-relative'
  )

  const containerClass = classNames(
    'container',
    { 'pb-5': !isProject && !isRed && !children && !isContact },
    { 'pb-4': isProject || isRed || children || isContact }
  )

  const colClass = classNames(
    'animate1 animate--top1',
    { 'col-md-10': !isProject },
    { 'col-md-8': isProject },
    { 'pb-md-6': children || isContact }
  )

  const typeColorClass = classNames(
    { 'hdln--white': !isRed },
    { 'hdln--dark-blue': isRed }
  )

  const headlineClass = classNames(
    'hdln--h1 mb-0 mb-md-2 d-block d-md-flex flex-wrap',
    typeColorClass
  )

  const subHeadlineClass = classNames(styles.subheadline, typeColorClass)
  const colorStyle = {
    backgroundColor: color.color
  }
  const bgObj = bgImage ? { backgroundImage: 'url(' + bgImage + ')' } : colorStyle

  const handleScroll = event => {
    const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
    const distance = Math.round(scrollPos * 0.2)
    const transformLeft = { transform: 'translateX(-' + distance + 'px)' }
    const transformRight = { transform: 'translateX(' + distance + 'px)' }

    if (scrollPos < 800) {
      setCloudTransformLeft(transformLeft)
      setCloudTransformRight(transformRight)
    }
  }

  useEffect(() => {
    if (Array.isArray(scrollSection) && !isRed) {
      scrollSection.push(heroRef)
    }

    return () => {
      const index = scrollSection.indexOf(heroRef)
      if (index > -1) {
        scrollSection.splice(index, 1)
      }
    }
  }, [])

  useEffect(() => {
    if (isDesignSprint) {
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (isDesignSprint) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <section
      className={sectionClass}
      style={bgObj}
      ref={heroRef}
    >
      <div className={containerClass}>
        <div className='row'>
          <div className={colClass}>
            <Fade bottom duration={600}>
              <h1 className={headlineClass}>{title}</h1>
            </Fade>
            <FadeBottom delay={400} >
              <h2 className={subHeadlineClass}>{subheadline}</h2>
            </FadeBottom>
          </div>

          {isProject && image &&
            <div className='col-8 offset-2 col-md-4 offset-md-0'>
              <img src={image} alt={title} className={styles.sideImg} />
            </div>
          }

          {children}
          {isContact &&
            <FooterBottom isHeader />
          }
        </div>
      </div>

      {isDesignSprint &&
        <>
          <div className={styles.glimmer} />
          <div class={styles.cloudLeft} style={cloudTransformLeft} />
          <div class={styles.cloudRight} style={cloudTransformRight} />
        </>
      }

    </section>
  )
}

export default HeroSubpage
