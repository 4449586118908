import
{
  Hero,
  HeroSubpage,
  SectionProjectInfo,
  SectionWorkList,
  ProjectImg,
  SectionTwoColQuote,
  SectionTwoColPhone,
  SectionServicesList,
  SectionTitleLeadImg,
  SectionStaff,
  SectionLogos,
  SectionServiceSteps,
  FootnoteList,
  SectionDropLine,
  SectionSendMessage,
  SectionGallery,
  SectionOpenPositions,
  SectionApplication,
  SectionStandard
} from '../components'

export const sliceMapping = {
  PrismicProjectBodyProjectHero: {
    component: HeroSubpage,
    dataToProps: (data, color) => {
      const {
        hero_title: { text: title },
        hero_subtitle: { text: subheadline },
        hero_background: { url: bgImage },
        hero_image: { url: image }
      } = data.primary

      return { title, subheadline, bgImage, image, color }
    },
  },
  PrismicProjectBodyProjectInfo: {
    component: SectionProjectInfo,
    dataToProps: (data, color) => {
      const {
        info_title: { text: title },
        info_text: { html: text },
        info_logo: { url: logo },
        info_expertise: { html: expertise },
        info_link_text: { text: linkText },
        info_year: { text: year }
      } = data.primary

      const link = (data.primary.info_link && data.primary.info_link.url) ? data.primary.info_link.url : null
      return { title, text, logo, expertise, link, linkText, year, color }
    },

  },
  PrismicProjectBodyImageIpadIphone: {
    component: ProjectImg,
    dataToProps: data => {
      const {
        img_one: { url: image },
        img_two: { url: mobileImg },
        img_text: { html: text }
      } = data.primary

      return { image, mobileImg, text }
    },
  },
  PrismicProjectBodyImageSingle: {
    component: ProjectImg,
    dataToProps: data => {
      const {
        img_one: { url: image },
        img_text: { html: text }
      } = data.primary

      return { image, text }
    },
  },
  PrismicProjectBodyImageTriple: {
    component: ProjectImg,
    dataToProps: data => {
      const {
        img_one: { url: image },
        img_two: { url: mobileImg },
        img_three: { url: mobileImgThree },
        img_text: { html: text }
      } = data.primary

      return { image, mobileImg, mobileImgThree, text }
    },
  },
  PrismicProjectBodyTwoColWImage: {
    component: SectionTwoColPhone,
    dataToProps: (data, color) => {
      const {
        headline: { text: title },
        text: { html: text },
        img: { url: image },
        col_order: order
      } = data.primary

      return { title, text, image, order, color }
    },
  },
  PrismicProjectBodyTwoColQuote: {
    component: SectionTwoColQuote,
    dataToProps: (data, color) => {
      const {
        headline: { text: title },
        quote: { text },
        author: { text: name },
        job_title: { text: job },
        author_img: { url: image, alt }
      } = data.primary

      return { title, text, name, job, image, alt, color }
    },
  },
  PrismicProjectBodyMoreWork: {
    component: SectionWorkList,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { text: subtitle },
        link_text: { text: linkText },
        link: { url: link }
      } = data.primary

      const items = extractProjectList(data.items)

      return { title, subtitle, linkText, link, items }
    }
  },
  PrismicPageBodyMoreWork: {
    component: SectionWorkList,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { text: subtitle }
      } = data.primary

      const linkText = data.primary.link_text && data.primary.link_text.text
      const link = data.primary.link && data.primary.link.url

      const items = extractProjectList(data.items)

      return { title, subtitle, linkText, link, items }
    }
  },
  PrismicPageBodyHero: {
    component: Hero,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { text: subheadline },
        img: { url: img, alt }
      } = data.primary

      return { title, subheadline, img, alt }
    }
  },
  PrismicPageBodyServices: {
    component: SectionServicesList,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { text: lead }
      } = data.primary

      const linkText = (data.primary.link_text && data.primary.link_text.text) ? data.primary.link_text.text : null
      const link = (data.primary.link && data.primary.link.url) ? data.primary.link.url : null

      const items = data.items.map((item, i) => {
        return {
          title: item.headline.text,
          text: item.text.html,
          image: item.img.url,
          alt: item.img.alt,
          linkText: item.link_text.text ? item.link_text.text : false,
          link: (item.link && item.link.url) ? item.link.url : false,
          imgTag: (item.img_tag && item.img_tag.text) ? item.img_tag.text : false
        }
      })
      return { title, lead, items, link, linkText }
    }
  },
  PrismicPageBodyTwoColQuote: {
    component: SectionTwoColQuote,
    dataToProps: data => {
      const {
        headline: { text: title },
        quote: { text },
        author: { text: name },
        job_title: { text: job },
        author_img: { url: image, alt }
      } = data.primary

      return { title, text, name, job, image, alt }
    }
  },
  PrismicPageBodySubpageHero: {
    component: HeroSubpage,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { text: subheadline },
        img: { url: bgImage, alt },
      } = data.primary

      const isRed = data.primary.type === 'red'
      const isContact = data.primary.type === 'contact'
      const isDesignSprint = data.primary.design_sprint === 'true'

      return { title, subheadline, bgImage, alt, isRed, isContact, isDesignSprint }
    }
  },
  PrismicPageBodyTitleLeadTextImage: {
    component: SectionTitleLeadImg,
    dataToProps: data => {
      const {
        headline: { text: title },
        lead: { html: lead },
        img: { url: image, alt },
        text: { html: text }
      } = data.primary

      return { title, lead, text, image, alt }
    }
  },
  PrismicPageBodyStaffList: {
    component: SectionStaff,
    dataToProps: data => {
      const {
        headline: { text: title },
        subheadline: { html: subtitle }
      } = data.primary

      const items = data.items.map((item, i) => {
        const {
          staff_items: {
            document: {
              data: docData
            }
          }
        } = item

        return {
          name: docData.name.text,
          title: docData.job_title.text,
          image: docData.img.url,
          alt: docData.img.alt,
          email: docData.email.text,
          info: docData.info.html,
          linkedin: docData.linkedin.text,
          instagram: docData.instagram && docData.instagram.text,
          link: docData.link.url
        }
      })
      return { title, subtitle, items }
    }
  },
  PrismicPageBodyLogoList: {
    component: SectionLogos,
    dataToProps: data => {
      const {
        headline: { text: title },
        subtitle: { text: subtitle }
      } = data.primary

      const items = data.items.map((item, i) => {
        return {
          image: item.img.url,
          alt: item.img.alt,
          link: (item.link && item.link.url) ? item.link.url : null
        }
      })
      return { title, subtitle, items }
    }
  },
  PrismicPageBodyServiceSteps: {
    component: SectionServiceSteps,
    dataToProps: data => {
      const {
        headline: { text: title },
        lead: { html: text }
      } = data.primary

      const steps = data.items.map((item, i) => {
        return {
          nr: item.step_title.text,
          text: item.step_text.html
        }
      })
      return { title, text, steps }
    }
  },
  PrismicPageBodyFootnotes: {
    component: FootnoteList,
    dataToProps: data => {
      const footnotes = data.items.map((item, i) => {
        return {
          link: item.link.url,
          text: item.link_text.text
        }
      })
      return { footnotes }
    }
  },
  PrismicPageBodyDropUsALine: {
    component: SectionDropLine,
    dataToProps: data => {
      const {
        headline: { text: title },
        link: { url: link },
        link_text: { text: linkText }
      } = data.primary

      return { title, link, linkText }
    }
  },
  PrismicPageBodySendUsAMessage: {
    component: SectionSendMessage,
    dataToProps: data => {
      const {
        headline: { text: title },
        text: { html: text }
      } = data.primary

      return { title, text }
    }
  },
  PrismicPageBodyGallery: {
    component: SectionGallery,
    dataToProps: data => {
      const {
        headline: { text: title },
        subtitle: { text: subtitle },
        type
      } = data.primary

      const images = data.items.map((item, i) => {
        return {
          src: item.img.url,
          alt: item.img.alt
        }
      })
      const fullWidth = type === 'fullWidth'

      return { title, subtitle, images, fullWidth }
    }
  },
  // todo: remove
  PrismicPageBodyInstagram: {
    component: () => null,
    dataToProps: () => {}
  },
  PrismicPageBodyOpenPositions: {
    component: SectionOpenPositions,
    dataToProps: data => {
      const {
        headline: { text: title },
        text: { html: text }
      } = data.primary

      const items = data.items.map((item, i) => {
        return {
          title: item.link_title.text,
          link: item.link.url
        }
      })

      return { title, text, items }
    }
  },
  PrismicPageBodyResumeForm: {
    component: SectionApplication,
    dataToProps: data => {
      const {
        headline: { text: title },
        text: { html: text }
      } = data.primary

      return { title, text }
    }
  },
  PrismicPageBodyArticleBody: {
    component: SectionStandard,
    dataToProps: data => {
      const {
        texti: { html: text }
      } = data.primary

      return { text }
    }
  }
}

const extractProjectList = items => (
  items
    .filter(item => !!item.project.document)
    .map((item, i) => {
      const {
        project: {
          document: {
            data: docData
          },
          url: projectUrl
        }
      } = item

      return {
        title: docData.headline.text,
        client: docData.title.text,
        image: docData.project_image.url,
        alt: docData.project_image.alt,
        link: projectUrl,
        caseStudy: docData.case_study
      }
    })
)
