import React from 'react'
import { Link, WorkListItem, FadeBottom } from '../'
import classNames from 'classnames'

const SectionWorkList = ({ title, subtitle, linkText, link, items, greyBg, largeTitle }) => {
  const sectionClass = classNames(
    { 'bg--grey pb-md-100 pt-160': greyBg },
    { 'py-md-100': !greyBg },
    'py-4'
  )

  return (
    <section className={sectionClass}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8'>
            {!largeTitle &&
              <>
                <FadeBottom delay={200}>
                  <Link to={link} className={classNames(
                    'hdln--secondary',
                    { 'd-none': !link }
                  )}>
                    {title}
                  </Link>
                  <span className={classNames(
                    'hdln--secondary',
                    { 'd-none': link }
                  )}>
                    {title}
                  </span>
                </FadeBottom>
                {subtitle &&
                  <FadeBottom>
                    <div className='hdln-skraut--1'>
                      <h1 className='hdln--2 hdln--gradient pr-3 mb-0 pt-md-2'>{subtitle}</h1>
                    </div>
                  </FadeBottom>
                }
              </>
            }

            {largeTitle &&
              <>
                <FadeBottom deay={200}>
                  <p className='lead lead--large pb-md-6'>{title}</p>
                </FadeBottom>
                <FadeBottom>
                  <h1 className='hdln--1 pr-3 mb-0 pt-5 pt-md-6'>{subtitle}</h1>
                </FadeBottom>
              </>
            }
          </div>

          {!largeTitle && linkText &&
            <div className='col-lg-4 col-md-4 text-md-right pt-md-4 d-none d-md-block'>
              <FadeBottom delay={200}>
                <Link to={link} className='link--seemore'>{linkText}</Link>
              </FadeBottom>
            </div>
          }
        </div>

        <div className='row pt-3 pt-4'>
          {items.map((item, i) => {
            const itemClass = classNames(
              'col-md-6',
              { 'mt-5': i > 0 },
              { 'mb-md-5 mt-md-0': !(i % 2) }
            )

            return (
              <WorkListItem
                key={i}
                title={item.title}
                client={item.client}
                image={item.image}
                alt={item.alt}
                className={itemClass}
                link={item.link}
                odd={i % 2}
                caseStudy={item.caseStudy === 'true'}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SectionWorkList
