import React from 'react'
import styles from './SectionLogos.module.scss'
import { Link, FadeBottom } from '../'
import classNames from 'classnames'

const SectionLogos = ({ title, subtitle, items }) => {
  return (
    <section className='container pt-4 pt-md-100 pb-3 pb-md-4'>
      <div className='row'>
        <div className='col-md-12'>
          <FadeBottom delay={200}>
            <span className='hdln--secondary'>{subtitle}</span>
          </FadeBottom>
          <FadeBottom>
            <div className='hdln-skraut--3'>
              <h1 className='hdln--2 hdln--gradient pr-3 mb-0 pt-md-2'>{title}</h1>
            </div>
          </FadeBottom>
        </div>
      </div>

      <div className='row pt-4'>
        <div className='col-12 d-flex flex-row flex-wrap'>
          {items.map((item, i) => {
            const delay = i % 2 ? 0 : 200
            return (
              <Link to={item.link} className={classNames(styles.logo, 'pb-4 pb-md-6')} key={i} >
                <FadeBottom delay={delay}>
                  <img src={item.image} alt={item.name} />
                </FadeBottom>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default SectionLogos
