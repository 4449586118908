import React from 'react'
import styles from './ProjectImg.module.scss'
import classNames from 'classnames'
import { ProjectImgPhone, FadeBottom } from '../'

const ProjectImg = ({ image, alt, mobileImg, mobileImgThree, text }) => {
  const imgStyle = classNames(
    'w-100',
    styles.img,
    { [styles.ipadImg]: mobileImg }
  )

  const threeImgPhoneClass = classNames(
    'd-flex flex-row justify-content-between',
    styles.mobileImgThreeContainer
  )
  return (
    <section className='container py-4 py-md-100'>
      <div className='row'>
        <div className='col-12 position-relative'>
          {!mobileImg && !mobileImgThree &&
            <FadeBottom>
              <img src={image} className='w-100' alt={alt} className={imgStyle} />
            </FadeBottom>
          }

          {mobileImg && !mobileImgThree &&
            <FadeBottom>
              <div className='pb-5'>
                <div className={styles.ipadImg} style={{ backgroundImage: 'url(' + image + ')' }} />
                <ProjectImgPhone image={mobileImg} className={styles.mobileImg} />
              </div>
            </FadeBottom>
          }

          {mobileImgThree && mobileImg && image &&
            <div className={styles.mobileVertScroll}>
              <FadeBottom>
                <div className={threeImgPhoneClass}>
                  <ProjectImgPhone image={image} className={styles.mobileImgThree} />
                  <ProjectImgPhone image={mobileImg} className={styles.mobileImgThree} />
                  <ProjectImgPhone image={mobileImgThree} className={styles.mobileImgThree} />
                </div>
              </FadeBottom>
            </div>
          }
        </div>
      </div>

      {text &&
        <div className='row pt-4'>
          <FadeBottom>
            <div className={classNames('col-md-8 offset-md-2 mr-3 mr-md-0 text-md-center', styles.captionContainer)}>
              <div className={classNames(styles.caption, 'pt-1')} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          </FadeBottom>
        </div>
      }
    </section>
  )
}

export default ProjectImg
