import React from 'react'
import styles from './TwoColQuote.module.scss'
import classNames from 'classnames'
import { FadeBottom } from '../'

const TwoColQuote = ({ title, text, image, alt, className, name, job, reverse, color }) => {
  const firstColClass = classNames(
    { 'col-md-5': !reverse },
    { 'col-md-5 offset-md-1 order-md-2': reverse }
  )

  const secondColClass = classNames(
    'pt-3',
    { 'col-md-6 offset-md-1': !reverse && title },
    { 'col-md-6 order-md-1': reverse && title },
    { 'col-md-8': !title }
  )

  const authorStyle = {
    backgroundImage: 'url(' + image + ')'
  }

  const headlineColor = { color: color }

  return (
    <div className={classNames(className, 'row pt-4 pb-5 py-md-6 d-flex justify-content-center')}>
      {title &&
        <div className={firstColClass}>
          <FadeBottom>
            <h2 className='hdln--3 pt-1' style={headlineColor}>{title}</h2>
          </FadeBottom>
        </div>
      }

      <div className={secondColClass}>
        <FadeBottom delay={200}>
          <blockquote
            className={classNames(
              { 'text-align-center': !title }
            )}
          >
            {text}
          </blockquote>

          <div className={classNames(
            'pt-3 pt-md-4 d-flex flex-row',
            { 'd-flex justify-content-center': !title }
          )}>
            <div className={styles.authorImg} style={authorStyle} />
            <div className='pl-3 d-flex flex-column justify-content-center'>
              <h3 className='hdln--author'>{name}</h3>
              <span className='hdln--subheadline'>{job}</span>
            </div>
          </div>
        </FadeBottom>
      </div>
    </div>
  )
}

export default TwoColQuote
