import React, { Component, Fragment, createRef } from 'react'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import { Navigation, Logo, Footer } from '../../components/'
import '../../styles/index.scss'
import ScrollSectionContext from '../../context/ScrollContext'
import { throttle } from 'lodash'
import withFooterContent from '../../prismic/withFooterContent'
import kosmosImage from '../../assets/images/kosmosImage.png'

const siteUrl = process.env.GATSBY_HOSTNAME || 'https://kosmosogkaos.is'
const FooterCmp = withFooterContent(Footer)

class MainLayout extends Component {
  static contextType = ScrollSectionContext

  constructor (props) {
    super(props)

    this.state = {
      logoOffset: 0,
      subOffset: 0,
      subOverColor: false,
      colorSectionInView: false,
      primaryLogoColor: '#ffffff',
      secondaryLogoColor: '#251D4E',
    }

    this.mainContentRef = createRef()
    this.timer = null
    this.adjustLogoColor = this.adjustLogoColor.bind(this)
    this.throttledLogoColorAdjustment = throttle(this.adjustLogoColor, 100)
    this.isMobile = this.isMobile.bind(this)
    this.getCoordinates = this.getCoordinates.bind(this)
  }

  componentDidMount () {
    this.timer = setTimeout(() => { this.adjustLogoColor() }, 500)
    window.addEventListener('scroll', this.throttledLogoColorAdjustment)
    window.addEventListener('resize', this.adjustLogoColor)
  }

  getCoordinates (sections) {
    let arr = []

    for (let i = 0; i < sections.length; i++) {
      if (sections[i].current) {
        const obj = {
          top: sections[i].current.getBoundingClientRect().top,
          bottom: sections[i].current.getBoundingClientRect().bottom,
          height: sections[i].current.getBoundingClientRect().height
        }
        arr.push(obj)
      }
    }

    return arr
  }

  componentWillUnmount () {
    clearTimeout(this.timer)
    window.removeEventListener('scroll', this.throttledLogoColorAdjustment)
    window.removeEventListener('resize', this.adjustLogoColor)
  }

  isMobile () {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    const isMobile = width < 400
    return isMobile
  }

  adjustLogoColor () {
    const { darkTheme } = this.props
    // TOOD: get nav bounds by using ref
    const logoStart = window.innerHeight - (this.isMobile() ? 206 : 256)
    const logoHeight = (this.isMobile() ? 120 : 200)
    const subStart = 112
    const subHeight = 305
    const mainContentBounds = this.mainContentRef.current.getBoundingClientRect()
    let isColorSection = false
    let isLogoOverColor = false
    let isSubOverColor = false
    let offset = 0
    let subOffset = 0

    this.coloredSections = this.getCoordinates(this.context)

    if (!darkTheme) {
      for (let i = 0; i < this.coloredSections.length; i++) {
        const curr = this.coloredSections[i]

        // TOOD: get nav bounds by using
        // bounds for main navref
        if (curr.top < 60 &&
          curr.top > -curr.height + 60) {
          isColorSection = true
        }

        // TOOD: get logo bounds by using ref
        // bounds for logo
        if (curr.bottom > logoStart &&
          curr.top < logoStart) {
          offset = Math.max(0, (logoStart + logoHeight) - curr.bottom)
          isLogoOverColor = true
        }

        // bounds for logo if color section is coming from below
        if (curr.top < logoStart + logoHeight &&
          curr.top > logoStart) {
          offset = (logoStart + logoHeight) - curr.top
        }

        // bounds for subnav
        if (curr.bottom > subStart &&
          curr.top < subStart) {
          subOffset = Math.max(0, (subStart + subHeight) - curr.bottom)
          isSubOverColor = true
        }

        // bounds for logo if color section is coming from below
        if (curr.top < subStart + subHeight &&
          curr.top > subStart) {
          subOffset = (subStart + subHeight) - curr.top
        }
      }
    }

    // dark theme logo color
    if (darkTheme && mainContentBounds.bottom > logoStart) {
      isLogoOverColor = true
    }

    // darktheme nav
    if (darkTheme && mainContentBounds.bottom > 60) {
      isColorSection = true
    }

    if (isColorSection) {
      this.setState({ colorSectionInView: false })
    } else if (!this.state.colorSectionInView) {
      this.setState({ colorSectionInView: true })
    }

    if (isLogoOverColor) {
      this.setState({ primaryLogoColor: '#ffffff', secondaryLogoColor: '#251D4E' })
    } else {
      this.setState({ primaryLogoColor: '#251D4E', secondaryLogoColor: '#ffffff' })
    }

    if (isSubOverColor) {
      this.setState({ subOverColor: false })
    } else if (!this.state.subOverColor) {
      this.setState({ subOverColor: true })
    }

    this.setState({ logoOffset: offset, subOffset: subOffset })
  }

  render () {
    const { hasNoHero, hasSubNav, redFooter, whiteBG, extraTopPadding, darkTheme } = this.props
    const {
      colorSectionInView,
      logoOffset,
      subOffset,
      primaryLogoColor,
      secondaryLogoColor,
      subOverColor } = this.state

    const bgClass = classNames(
      { 'bg-white': whiteBG },
      { 'pt-5': extraTopPadding },
      { 'dark-theme': darkTheme }
    )
    return (
      <Fragment>
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
          <title>Kosmos & Kaos</title>
          <link data-react-helmet='true' rel='icon' type='image/gif' href='/kk-fav.gif'></link>
          <meta name='msapplication-TileColor' content='#da532c' />
          <meta name='theme-color' content='#ffffff' />
          <meta property='og:type' content='website' />
          <meta property='og:url' content={siteUrl} />
          <meta property='og:title' content='Kosmos & Kaos' />
          <meta property='og:image' content={kosmosImage} />
          { /* eslint-disable */ }
          <meta data-react-helmet='true' name='description' content='Bottom line digital. A carefully designed user experience is damn good business.' />
          { /* eslint-enable */ }
          <html lang='is' />
        </Helmet>

        <nav className='sr-only' aria-label='skip to main content'>
          <a href='#main' className='sr-only'>Skip to main content</a>
        </nav>

        <Navigation
          white={!colorSectionInView && !hasNoHero}
          hasSubNav={hasSubNav}
          reverseColor={!subOverColor && !hasNoHero}
          offset={subOffset}
        />
        <Logo
          color={primaryLogoColor}
          secondaryColor={secondaryLogoColor}
          moveValue={logoOffset}
        />
        <main id='main' className={bgClass} ref={this.mainContentRef} >
          {this.props.children}
        </main>

        <FooterCmp isRed={redFooter} />
      </Fragment>
    )
  }
}

export default MainLayout
