import React from 'react'
import { Link, FadeBottom } from '../'
import styles from './TwoColImgText.module.scss'
import classNames from 'classnames'

const TwoColImgText = ({ title, text, image, alt, className, link, linkText, extend, reverse, imgTag }) => {
  const firstColClass = classNames(
    { 'col-md-5': !reverse },
    { 'col-md-5 offset-md-1 order-md-2': reverse },
    { 'col-md-6': !reverse && extend },
    styles.img
  )

  const secondColClass = classNames(
    { 'col-md-6': !reverse },
    { 'offset-md-1': !reverse && !extend },
    { 'col-md-6 order-md-1': reverse }
  )

  const textClass = classNames(
    'pt-1 pt-md-3',
    styles.text
  )

  const imgTagClass = classNames(
    styles.imgTag,
    { [styles.imgTagExtend]: extend }
  )

  return (
    <div className={classNames(className, 'row pb-4 pb-md-5')}>
      <FadeBottom>
        <div className={firstColClass}>
          <img className='w-100' src={image} alt={alt} />
        </div>
      </FadeBottom>

      <FadeBottom delay={200}>
        <div className={secondColClass}>
          {imgTag &&
            <div className={imgTagClass}>{imgTag}</div>
          }
          <h2 className='hdln--3 mb-0 pt-4 pt-md-5'>{title}</h2>
          <div className={textClass} dangerouslySetInnerHTML={{ __html: text }} />
          {link &&
            <Link className='link--more mt-3 d-inline-block' to={link}>{linkText}</Link>
          }
        </div>
      </FadeBottom>
    </div>
  )
}

export default TwoColImgText
