import React from 'react'
import { Link, FadeBottom } from '../'

const SectionOpenPositions = ({ title, text, items }) => {
  return (
    <section className='container py-4 py-md-100'>
      <div className='row'>
        <FadeBottom>
          <div className='col-md-5 pb-3'><h1 className='hdln--3'>{title}</h1></div>
        </FadeBottom>
        <FadeBottom delay={200}>
          <div className='col-md-5 offset-md-1'>
            {items.map((item, i) => {
              return (
                <Link key={i} to={item.link} className='link--row'>{item.title}</Link>
              )
            })}

            <div className='pt-3 pt-md-4 mt-2' dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </FadeBottom>
      </div>
    </section>
  )
}

export default SectionOpenPositions
