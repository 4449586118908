import React from 'react'
import { ProjectImgPhone, FadeBottom } from '../'
import classNames from 'classnames'

const SectionTwoColPhone = ({ title, text, image, order, color }) => {
  const headlineColor = { color: color.color }

  const firstColClass = classNames(
    'col-md-6',
    { 'offset-md-1 order-md-2': order === 'Right' }
  )
  const secondColClass = classNames(
    'col-md-5',
    { 'offset-md-1': order !== 'Right' },
    { 'order-md-1': order === 'Right' }
  )
  return (
    <section className='container py-4 py-md-100'>
      <div className='row'>
        <div className={firstColClass}>
          <FadeBottom>
            <h1 className='hdln--3 pt-md-5' style={headlineColor}>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </FadeBottom>
        </div>

        <div className={secondColClass}>
          <FadeBottom delay={200}>
            <div className='pt-3 pt-md-0 px-4 px-md-0'>
              <ProjectImgPhone image={image} />
            </div>
          </FadeBottom>
        </div>
      </div>
    </section>
  )
}

export default SectionTwoColPhone
