import React from 'react'
import styles from './ProjectImgPhone.module.scss'
import classNames from 'classnames'

const ProjectImgPhone = ({ image, className }) => {
  return (
    <div className={classNames(styles.wrap, className)}>
      <img src={image} alt='screenshot' className={styles.container} />
    </div>
  )
}

export default ProjectImgPhone
