import React from 'react'
import styles from './Navigation.module.scss'
import classNames from 'classnames'
import { Link } from '..'

const Navigation = ({ white, hasSubNav, reverseColor, offset }) => {
  const linkClass = classNames(
    'hdln--nav',
    'ml-md-4',
    'hdln--hover-pink',
    { 'hdln--white': white }
  )

  const subLinkClass = classNames(
    'hdln--subnav', 'mr-3',
    { 'hdln--white': !reverseColor }
  )

  const subLinkClassSecondary = classNames(
    'hdln--subnav', 'mr-3',
    { 'hdln--white': reverseColor }
  )

  const navClass = classNames(
    'float-md-right px-3 px-md-0 py-4 pr-md-100 d-flex d-md-block justify-content-between',
    { [styles.navWhite]: white }
  )

  const activeTabClass = classNames(
    'hdln--nav-active',
    { 'hdln--nav-active white': white }
  )

  const styleObj = { height: (305 - offset) + 'px' }

  return (
    <header className={styles.header}>
      <nav className={navClass}>
        <Link to='/work' activeClassName='hdln--nav-active' className={linkClass}>
          <span word='Work'>Work</span>
        </Link>
        <Link to='/about-us' activeClassName={activeTabClass} className={linkClass}>
          <span word='About'>About</span>
        </Link>
        <Link to='/services' activeClassName={activeTabClass} className={linkClass}>
          <span word='Services'>Services</span>
        </Link>
        <Link to='/contact' activeClassName={activeTabClass} className={linkClass}>
          <span word='Contact'>Contact</span>
        </Link>
      </nav>

      {hasSubNav &&
        <>
          <nav className={styles.subNav}>
            <div className={styles.subNavContainer}>
              <Link to='/' className={subLinkClass}>Design</Link>
              <Link to='/' className={subLinkClass}>Development</Link>
              <Link to='/' className={subLinkClass}>Consulting</Link>
            </div>
          </nav>
          <nav
            className={classNames(styles.subNav, styles.secondary)}
            style={styleObj}
          >
            <div className={styles.subNavContainer}>
              <Link to='/servicesdesign' className={subLinkClassSecondary}>Design</Link>
              <Link to='/servicesdevelopment' className={subLinkClassSecondary}>Development</Link>
              <Link to='/servicesconsulting' className={subLinkClassSecondary}>Consulting</Link>
            </div>
          </nav>
        </>
      }
    </header>
  )
}

export default Navigation
