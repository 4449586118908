import React from 'react'
import { TwoColQuote } from '../'

const SectionTwoColQuote = ({ title, text, name, job, image, alt, color }) => {
  return (
    <section className='container'>
      <TwoColQuote
        title={title}
        text={text}
        image={image}
        alt={alt}
        name={name}
        job={job}
        color={color}
      />
    </section>
  )
}

export default SectionTwoColQuote
