import React from 'react'
import { Link, FadeBottom } from '../'
import styles from './WorkListItem.module.scss'
import classNames from 'classnames'

const WorkListItem = ({ title, client, image, alt, className, link, odd, caseStudy = false }) => {
  const delay = odd ? 200 : 0
  const displayTag = classNames(
    { 'd-none': !caseStudy }
  )
  return (
    <Link className={className} to={link}>
      <FadeBottom delay={delay}>
        <img className='w-100' src={image} alt={alt} />
        <div className={classNames(styles.tagContainer, displayTag)}>
          <p className={styles.caseStudy}>Case Study</p>
        </div>
        <span className='hdln--subheadline d-block py-2 pb-md-2 pt-md-3'>{client}</span>
        <h2 className='hdln--work mb-0'>{title}</h2>
      </FadeBottom>
    </Link>
  )
}

export default WorkListItem
